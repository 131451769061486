<template>
  <section class="login-main">
    <div class="login-inner">
      <div class="login-l">
        <Sidebar />
      </div>
      <div class="login-r">
        <div class="login-box">
          <div></div> <!-- Don't remove this empty div -->
          <div class="login-form">
            <div class="logo-icon">
              <img src="../assets/img/ri_user.svg" />
            </div>
            <h2>{{ $t("login.loginToYourAccount") }}
            </h2>
            <v-form>
              <label class="font-weight-regular">
                {{ $t("login.username") }}
              </label>
              <v-text-field hide-details="auto" v-model.trim="email" :error-messages="emailError"
                :placeholder="$t('login.username')" @blur="$v.email.$touch()" solo append-icon="mdi-account-outline"
                color="green  darken-4" class="mb-4" name="email"></v-text-field>
              <label class="font-weight-regular">
                {{ $t("login.password") }}
              </label>
              <v-text-field solo hide-details="auto" v-model.trim="password" :error-messages="passwordError"
                type="password" @blur="$v.password.$touch()" :placeholder="$t('login.password')"
                append-icon="mdi-lock-outline" color="green darken-4" class="mb-4" name="password"></v-text-field>
              <div class="form-btn-group">
                <button type="button" @click="submit" class="btn customBtn pattern-btn w-100">
                  <span> {{ $t("login.login") }}</span>
                </button>
              </div>
            </v-form>

            <div class="form-link">
              <p>{{ $t("leads.dontHaveAnAccount") }} <button @click="$router.push('/leads')">{{ $t("leads.signin") }}</button></p>
            </div>
          </div>
          <div class="login-footer">
            <div class="chrome-notification">
              {{ $t("login.browserSupportMessage") }}
              <img src="../assets/chrome.png" height="17" />
            </div>
            <p> {{ $t("login.allRightsReserved") }}
              <button @click="$router.push('/terms-condition')">
                {{ $t("login.term&condition") }}
              </button>
            </p>
          </div>
          <div class="login-r-btm">
            <div class="phone-dtl">
              <a href="tel:+1 714 334 5505">
                <img
                  :src="!this.isDarkMode ? require('../assets/img/icon-phone-call-green.svg') : require('../assets/img/icon-phone-call-blue.svg')" />
                <p>
                  {{ $t("leads.forHelpCallAdriaonat") }}
                  <b>714-334-5505</b>
                </p>
              </a>
            </div>
            <div class="social-dtl">
              <ul>
                <li>
                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/union-green.svg') : require('../assets/img/union-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/fb-green.svg') : require('../assets/img/fb-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/insta-green.svg') : require('../assets/img/insta-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/whatsapp-green.svg') : require('../assets/img/whatsapp-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/youtube-green.svg') : require('../assets/img/youtube-blue.svg')" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <template>
          <div class="text-center">
            <v-dialog v-model="dialog" width="500" content-class="rd-custom-modal">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  <h4>{{ $t("login.forceLogoutDialogTitle") }}</h4>
                </v-card-title>
                <v-card-text>
                  <div>
                    <p>
                      {{ $t("login.forceLogoutDialogMessage") }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn height="45" medium @click="dialog = false" dense class="btn customBtn red-fill-btn pattern-btn"
                    elevation="0">
                    {{ $t("login.cancel") }}
                  </v-btn>
                  <v-btn height="45" medium @click="forceLogin" dense class="btn customBtn pattern-btn" elevation="0">
                    {{ $t("login.forceLogout") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { RECURRING_BY_CARD, MONTHLY_BY_CARD } from "@/constants/common";
export default {
  name: "Login",
  components: {
    Sidebar: () => import("@/components/PublicAside"),
  },
  data() {
    return {
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_INVENTORY_URL,
      VUE_APP_RDL_YOUTUBE_LINK: process.env.VUE_APP_YOUTUBE_LINK,
      VUE_APP_RDL_WHATSAPP_LINK: process.env.VUE_APP_WHATSAPP_LINK,
      email: "",
      password: "",
      force_login: false,
      dialog: false,
      apiErrorMessage: null,
      loggedInMessage: null,
      errors: {
        email: null,
        password: null,
      },
      isDarkMode: false
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      // isDarkMode: "isDarkMode",
    }),
    emailError() {
      let error = [];
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("login.email"),
        });
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }

      return error;
    },
    passwordError() {
      let error = "";
      if (this.$v.password.$dirty && !this.$v.password.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("login.password"),
        });
      }
      if (this.errors && this.errors.password) {
        error = this.errors.password;
      }

      return error;
    },
  },
  created() {
    // Check local storage for theme preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.setAttribute('data-theme', savedTheme);
    }
  },

  methods: {
    ...mapActions({
      login: "user/login",
      getUser: "user/getUser",
      toggleDarkMode: "toggleDarkMode"
    }),
    toggleTheme() {
      // Toggle the dark mode state using a Vuex action
      this.toggleDarkMode(!this.isDarkMode);
      this.applyTheme();
    },
    applyTheme() {
      const theme = this.isDarkMode ? 'blue-theme' : 'green-theme';
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
    },
    async submit() {
      this.$v.$touch();
      this.dialog = false;
      if (this.email && this.password) {
        this.$store.commit("setLoader", true);
        try {
          await this.login({
            email: this.email,
            password: this.password,
            force_login: this.force_login,
          });
          if (this.user && this.user.is_new_user && !this.user.is_subscribed) {
            this.$router.push({ name: "PaymentDetails" });
          } else if (
            this.user &&
            !this.user.is_subscribed &&
            !this.user.is_on_grace_period
          ) {
            this.$router.push({ name: "PaymentDetails" });
          } else if (
            this.user &&
            this.user.has_failed_payment &&
            !this.user.is_on_grace_period &&
            [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
              this.user.payment_method
            )
          ) {
            this.$router.push({ name: "PaymentDetails" });
          } else {
            this.$router.push({ name: "MembersDirectory" });
          }
        } catch ({ message }) {
          if (message.logged_in) {
            this.loggedInMessage = message.logged_in[0];
            this.dialog = true;
          }
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },

    forceLogin() {
      try {
        this.force_login = true;
        this.submit();
      } finally {
        this.force_login = false;
        this.dialog = false;
      }
    },
  },
};
</script>
